import { ReactNode } from 'react';

import { Card } from 'components/Cards';
import { Grid, GridRow, GridItem } from 'components/Grid';
import { Text as Paragraph, Title } from 'components/Typography';
import { CarouselCard, FeatureContainer } from './styles';
import { AuthorableContentCard } from 'components/AuthorableContentCard';

import type { ICard } from '../Cards/types';
import { mapFeaturedContent } from './helpers/mapFeaturedContent';
import type { FeaturedContentItem } from './FeaturedContent';
import type { Dictionary } from 'service/dictionary/dictionaryService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { Slider } from '../SliderVanilla';

const NoContentMessage = ({ labels = {} }: { labels?: Record<string, string> }) => (
  <div className="text-center bg-lightBrown h-heroInnerNoImage pt-48">
    {labels?.noContent || 'No Featured Content'}
  </div>
);

export function isPlainCard(card: FeaturedContentItem): card is ICard {
  return (card as ICard)?.data !== undefined;
}

interface IFeaturedContent {
  id?: string;
  title?: string | null;
  superText?: string | null;
  items: FeaturedContentItem[];
  dictionary?: Dictionary | null;
}

export const FeaturedContent = ({ id, title, superText, items, dictionary }: IFeaturedContent) => {
  const { pathTranslations } = useGlobalContext();
  const idProp = `feat-cont-${id}`;
  const itemProp = (items as any) || [];
  const contentItems = mapFeaturedContent(
    itemProp,
    dictionary ?? { dictionaryItems: [] },
    pathTranslations,
  );
  const getContentCard = (item: FeaturedContentItem, index: number): ReactNode => {
    if (isPlainCard(item)) {
      return (
        <Card data-test={`${idProp}-content-card`} {...item} cardType="big" position={index + 1} />
      );
    }
    return <AuthorableContentCard data-test={`${idProp}-content-card`} {...item} />;
  };
  const getContentDisplay = () => {
    if (contentItems?.length === 0) {
      return <NoContentMessage data-test={`${idProp}-no-content-msg`} />;
    }
    if (contentItems?.length === 1) {
      return getContentCard(contentItems[0], 1);
    }
    return (
      <div data-test={`${idProp}-slider-wrapper`} className="feature-slider">
        <Slider data-test="card-carousel-slider" isFullWidth>
          {contentItems?.map((item: FeaturedContentItem, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <CarouselCard key={isPlainCard(item) ? item.data?.id ?? `card${index}` : item.copy}>
              {/* eslint-disable-next-line react/no-array-index-key */}
              <div className="md:pr-2.5 sm:pr-2" key={`featured-card-${index}`}>
                {getContentCard(item, index)}
              </div>
            </CarouselCard>
          ))}
        </Slider>
      </div>
    );
  };

  return (
    <FeatureContainer data-test={idProp}>
      <div data-test={`${idProp}-titles`} className="text-center md:mb-30 sm:mb-5">
        <Paragraph tag="p" type="lead" className="mb-2.5">
          {superText}
        </Paragraph>
        <Title tag="h2" type="md" className="mb-5">
          {title}
        </Title>
      </div>

      <Grid>
        <GridRow columns={16}>
          <GridItem colSpan={16}>{getContentDisplay()}</GridItem>
        </GridRow>
      </Grid>
    </FeatureContainer>
  );
};
