import styled from 'styled-components';

export const FeatureContainer = styled.div`
  .feature-card-content {
    top: -20px;
    @media screen and (min-width: 768px) {
      top: -30px;
    }
    @media screen and (min-width: 1024px) {
      top: -40px;
    }
  }
  .card-content {
    margin-bottom: -30px;
  }
  .card-content-big {
    margin-bottom: -20px;
  }
  .feature-slider {
    padding-right: 0;
    margin-right: 0;
    overflow: hidden;
    .slick-track {
      left: 3vw;
    }
    .slick-initialized {
      .slick-slide {
        padding: 0 0 50px 10px;
      }
    }
    @media screen and (max-width: 480px) {
      .slick-track {
        left: 0;
      }
    }
  }
`;

export const CarouselCard = styled.div`
  margin-right: 0px;
  min-width: 95%;
`;
CarouselCard.displayName = 'CarouselCard';
