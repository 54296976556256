import type { Maybe } from 'server/repository/hygraph/generated/graphqlTypes';
import type { FeaturedContentItem } from 'types/FeaturedContent';

import { NextLink } from 'components/NextLink';
import { isAuthorableContentCard, isFeaturedRecipeCard } from 'types/FeaturedContent';
import { DictionaryService, type Dictionary } from 'service/dictionary/dictionaryService';
import { LocalizationService } from 'service/localization/localizationService';
import type { PathTranslations } from 'service/localization/getPathTranslations';

export function mapFeaturedContent(
  cmsItems: FeaturedContentItem[],
  copyDictionary: Maybe<Dictionary>,
  pathTranslations: PathTranslations,
  contentHeight: string = '500px',
) {
  const copyDict: Record<string, string> = DictionaryService.mapDictionaryToRecords(copyDictionary);
  const difficultMapping = {
    0: copyDict.easy,
    1: copyDict.intermediate,
    2: copyDict.tough,
  };
  const allItems = cmsItems?.reduce((items: any[], itm: FeaturedContentItem) => {
    if (isAuthorableContentCard(itm)) {
      return [
        ...items,
        {
          title: itm.name,
          subtitle: itm.subtitle,
          copy: itm.copy,
          videoId: itm.videoId,
          image: itm.image?.asset?.[0],
          link: itm?.link && {
            url: LocalizationService.getLocalizedLinkPath({
              item: itm.link,
              pathTranslations,
            }),
            text: itm.link.displayName,
            openInNewTab: itm.link.openInNewTab ?? false,
          },
        },
      ];
    }

    if (isFeaturedRecipeCard(itm)) {
      return itm.recipeData?.Id
        ? [
            ...items,
            {
              data: {
                id: itm?.recipeData?.Id,
                title: itm?.recipeData?.Title,
                description: itm?.recipeData?.ShortDescription,
                categoryId: itm?.recipeData?.Category.Id,
                category: itm?.recipeData?.Category.Title,
                image: {
                  desktop: itm?.recipeData?.Images?.[0]?.Formats?.[0]?.Url ?? '',
                  mobile: itm?.recipeData?.Images?.[1]?.Formats?.[0]?.Url ?? '',
                  hero: itm?.recipeData?.Images?.[0]?.Formats?.[0]?.Url ?? '',
                  tablet: itm?.recipeData?.Images?.[0]?.Formats?.[0]?.Url ?? '',
                },
                information: {
                  preparationTime: `${itm?.recipeData?.PreparationTimeInMinutes} Min` || null,
                  rating: itm?.averageRatingScore || '0.0',
                  difficulty: itm?.recipeData.Difficulty || null,
                  totalTime:
                    `${
                      itm?.recipeData?.TotalTimeInMinutes ||
                      itm?.recipeData?.PreparationTimeInMinutes
                    } Min` || null,
                },
              },
              showInfo: true,
              link: {
                url: `/recipes/${itm?.recipeData?.slug}`,
                displayName: copyDict?.see_recipe_details || 'See Recipe',
                openInNewTab: itm.recipeData.link?.openInNewTab ?? false,
              },
              height: contentHeight,
              cardVariation: 'recipe',
              copyDictionary: difficultMapping,
              LinkComponent: NextLink,
            },
          ]
        : items;
    }
    return items;
  }, []);

  return allItems;
}
